import { useState, useEffect } from 'react';

import DealsImport from './DealsImport.js';
import ModalContainer from '../../../components/ModalContainer/ModalContainer.js';
import { showMemberDeals } from '../../../services/navigation/navigation.service';
import { useAuthContext } from '../../../context/AuthContext';
import { env } from '../../../env';

export const MDM = 'memberDealsModal';

export default function MemberDealsLoginModal() {
  const { userLoggedIn } = useAuthContext();
  const [modalTriggered, setModalTriggered] = useState(0);

  useEffect(() => {
    const modal = localStorage.getItem(MDM) || 0;

    if (userLoggedIn && !modal) {
      setModalTriggered(1);
      localStorage.setItem(MDM, 1);
    }

  }, [userLoggedIn]);

  // show only once, and delete on logout
  if (!modalTriggered) return null;

  // show only on NS and GuerillaPorn when user is logged in 
  if ((env.REACT_APP_PROPERTY_ID === '1' || env.REACT_APP_PROPERTY_ID === '22') && userLoggedIn) {
    return (
      <ModalContainer className='MemberDealsModal' showSignInModal={userLoggedIn} showOverlay>
        <div className="MD-Content">
          <div className="DealsModalAccount">No Thanks. Take Me to My Nakedsword!</div>
          <div className="dealsModalHeader">SPECIAL OFFERS EXCLUSIVE TO OUR MEMBERS</div>

          <DealsImport numberOfDisplayedOffers={6} />

          <button className="MemberDealsModalSeeMoreBtn cursor-pointer" onClick={() => showMemberDeals()}>
            SEE MORE OFFERS
          </button>
        </div>
      </ModalContainer>
    )
  }
  return null;
}  